.app {
  color: white;
  font-family: "Heebo", sans-serif;
  background: #f5f6fa;
}

.nav {
  padding: 2rem 0 0;
  font-weight: 500;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav img {
  max-height: 4rem;
}

.nav a {
  color: white;
  display: inline-block;
}

.nav a:hover {
  color: #bfc5ff;
}

.logo {
  margin: 1rem;
}

.hero {
  padding-bottom: 2rem;
  background: linear-gradient(180deg, #292061 0%, #4939ad 100%);
  text-align: center
}

.hero p {
  color: #bfc5ff;
  font-size: 1.25em;
  max-width: 650px;
  margin: 0 auto 2rem;
}

.hero .container {
  padding-top: 2rem;
}

h1 {
  font-size: 3.25em;
  font-weight: 500;
  letter-spacing: -0.025em;
  margin: 0.3em 0 0.5em;
  padding: 0 0.5em;
}

h2 {
  color: black;
  font-size: 1.375em;
  font-weight: 700;
  margin-bottom: 1rem;
}

h3 {
  color: #4d4d4d;
  margin: 2rem 0 1rem;
  font-size: 1rem;
}

h4 {
  color: #4d4d4d;
  margin: 2rem 0 1rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.container {
  padding: 6rem 0.5rem 2rem;
  max-width: 800px;
}

.description {
  color: black;
}

.snippet pre {
  margin-bottom: 0;
  background: white !important;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-size: 14px !important;
}

.protocols {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.protocol {
  color: black;
  height: 8rem;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-decoration: none;
  border: solid 2px transparent;
}

.protocol:not(.no-hover):hover,
.protocol.active {
  background-color: #f4f3ff;
  border: solid 2px #a899ff;
  cursor: pointer;
}

.protocol img {
  max-width: 70%;
}

.protocol-selector {
  margin-bottom: 1rem;
}

.text-shorten {
  text-overflow: ellipsis;
  overflow: hidden;
}

.table-responsive {
  min-height: 540px;
  max-width: 784px;
}

table {
  background-color: white !important;
  color: black;
  border-radius: 6px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

td {
  white-space: nowrap;
}

.table thead th {
  border-bottom: 1px solid #eceef0;
  width: 130px;
}

.text-danger {
  font-weight: 500;
  padding: 1rem;
  margin: 1rem;
  display: inline-block;
  border-radius: 6px;
  background: #ffe8e8;
}

.section {
  background: #4838ab;
}

.section .content {
  color: white;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
}

.section .content h1 {
  font-size: 38px;
}

.btn {
  margin: 1rem 0.5rem;
}

.in-development {
  padding-bottom: 5rem;
}

.loader {
  height: 537px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.footer {
  padding-top: 2rem;
  color: #d2d2d2;
}

.footer a {
  color: #d2d2d2;
  text-decoration: underline;
}

@media (max-width: 600px) {
  .protocols {
    grid-template-columns: repeat(2, 1fr);
  }
}